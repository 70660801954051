<template>
    <div class="text-center mt-16">
    <h1>SOFTWARE DEVELOPMENT SERVICES
FOR YOUR INNOVATIVE IDEAS</h1>
     <v-card
    class="pa-md-4 mx-lg-auto"
    width="50%" flat
  >
    <v-card-text>
      We help our partners accelerate disruption both within their organizations and industries. They strategize new ideas and obtain real business value by getting the most out of our production-ready custom software development services. Talk to our experts today!
    </v-card-text>
  </v-card>
  </div>
</template>

<script>
export default {

}
</script>

<style>

</style>
