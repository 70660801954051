<template>
    <div class="text-center mt-16">
    <h1>EMPOWERING OUR CLIENTS
TO SUCCEED WITH TECHNOLOGIES</h1>
     <v-card
    class="pa-md-4 mx-lg-auto"
    width="50%" flat
  >
    <v-card-text>
      For over 13 years, Sigma Pty Ltd has been helping IT firms, multinationals, and Fortune 500 companies create impactful software solutions. Learn why they have chosen us and how we have helped them to change people’s lives.
    </v-card-text>
  </v-card>
  </div>
</template>

<script>
export default {

}
</script>

<style>

</style>
