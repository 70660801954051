<template>
    <div class="text-center mt-16">
    <h1>CONTACT SIGMA PTY LTD</h1>
     <v-card
    class="pa-md-4 mx-lg-auto"
    width="50%" flat
  >
    <v-card-text>
    <p><a href="mailto:thesigmapty@gmail.com">thesigmapty@gmail.com</a></p>
    <p><a href="tel:0478514390">0478514390</a></p>
    </v-card-text>
  </v-card>
  </div>
</template>

<script>
export default {

}
</script>

<style>

</style>
