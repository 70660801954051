<template>
  <v-app>
    <v-navigation-drawer
      v-model="drawer"
      app
      clipped
      fixed
    >
      <v-list
        nav
        dense
      >
        <v-list-item-group
          v-model="group"
        >
          <v-list-item>
            <v-list-item-icon>
              <v-icon>mdi-domain</v-icon>
            </v-list-item-icon>
            <v-list-item-title><v-btn text to="/company">Company</v-btn></v-list-item-title>
          </v-list-item>

          <v-list-item>
            <v-list-item-icon>
              <v-icon>mdi-account</v-icon>
            </v-list-item-icon>
            <v-list-item-title><v-btn text to="/client">Client</v-btn></v-list-item-title>
          </v-list-item>
          <v-list-item>
            <v-list-item-icon>
              <v-icon>mdi-face-agent</v-icon>
            </v-list-item-icon>
            <v-list-item-title><v-btn text to="/services">Services</v-btn></v-list-item-title>
          </v-list-item>
          <v-list-item>
            <v-list-item-icon>
              <v-icon>mdi-information</v-icon>
            </v-list-item-icon>
            <v-list-item-title><v-btn text to="/about">About Us</v-btn></v-list-item-title>
          </v-list-item>
          <v-list-item>
            <v-list-item-icon>
              <v-icon>mdi-card-account-mail</v-icon>
            </v-list-item-icon>
            <v-list-item-title><v-btn text to="/contactus">Contact</v-btn></v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar app clipped-left
    >
      <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>

      <v-toolbar-title>
        <a href="/">
          <v-img
            alt="Sigma Logo"
            class="shrink mr-2"
            contain
            src="@/assets/logo.svg"
            transition="scale-transition"
            width="120"
          />
        </a>
      </v-toolbar-title>

      <v-spacer></v-spacer>
      <v-btn text to="/company">Company</v-btn>
      <v-btn text to="/client">Client</v-btn>
      <v-btn text to="/services">Services</v-btn>
      <v-btn text to="/about">About Us</v-btn>
      <v-btn text to="/contactus">Contact</v-btn>
    </v-app-bar>

  <!-- Sizes your content based upon application components -->
  <v-main>

    <!-- Provides the application the proper gutter -->
    <v-container fluid>

      <!-- If using vue-router -->
      <router-view></router-view>
    </v-container>
  </v-main>

  <v-footer
    app
    padless
  >
    <v-card
      class="flex"
      flat
      tile dark
    >
      <v-card-title class="grey darken-1">
        <strong class="subheading">Get connected with us on social networks!</strong>

        <v-spacer></v-spacer>

        <v-btn
          v-for="icon in icons"
          :key="icon"
          class="mx-4"
          dark
          icon
          target="_blank"
          :href="icon.link"
        >
          <v-icon size="24px">
            {{ icon.icon }}
          </v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text class="py-2 text-center">
        {{ new Date().getFullYear() }} — <strong>Sigma Pty Ltd</strong>
      </v-card-text>
    </v-card>
  </v-footer>

    <!-- <v-main>
      <router-view/>
    </v-main> -->
  </v-app>
</template>

<script>

export default {
  name: 'App',

  data: () => ({
    drawer: false,
    group: null,
    icons: [
      { link: 'http://facebook.com/thesigmacompany', icon: 'mdi-facebook' },
      { link: 'http://twitter.com/thesigmacompany', icon: 'mdi-twitter' },
      { link: 'http://linkedin.com/thesigmacompany', icon: 'mdi-linkedin' },
      { link: 'http://instagram.com/thesigmacompany', icon: 'mdi-instagram' }
    ]
  })
}
</script>
