<template>
  <div class="text-center mt-16">
    <h1>ABOUT SIGMA PTY LTD</h1>
     <v-card
    class="pa-md-4 mx-lg-auto"
    width="50%" flat
  >
    <v-card-text>
      We are a digital transformation consultancy and engineering company that delivers cutting edge solutions for global organisations and technology startups. Since 2007 we have been helping companies and established brands reimagine their business through digitalisation.
    </v-card-text>
  </v-card>
  </div>
</template>

<script>
export default {

}
</script>

<style>

</style>
