<template>
  <v-container>
    <v-row class="text-center">
      <v-col cols="12">
        <v-img
          :src="require('../assets/logo.svg')"
          class="my-3"
          contain
          height="200"
        />
      </v-col>

      <v-col class="mb-4">
        <h1 class="display-2 font-weight-bold mb-3">
          Welcome to Sigma
        </h1>

        <p class="subheading font-weight-regular">
          For help and collaboration with other Sigma developers,
          <br>please join our online
          <a
            href="#"
            target="_blank"
          >Community</a>
        </p>
      </v-col>

      <v-col
        class="mb-5"
        cols="12"
      >
        <h2 class="headline font-weight-bold mb-3">
          What's new?
        </h2>

        <v-row justify="center">
          <a
            v-for="(next, i) in whatsNext"
            :key="i"
            :href="next.href"
            class="subheading mx-3"
            target="_blank"
          >
            {{ next.text }}
          </a>
        </v-row>
      </v-col>

      <v-col
        class="mb-5"
        cols="12"
      >
        <h2 class="headline font-weight-bold mb-3">
          Important Links
        </h2>

        <v-row justify="center">
          <a
            v-for="(link, i) in importantLinks"
            :key="i"
            :href="link.href"
            class="subheading mx-3"
            target="_blank"
          >
            {{ link.text }}
          </a>
        </v-row>
      </v-col>

    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'HelloWorld',

  data: () => ({
    ecosystem: [
      {
        text: 'vuetify-loader',
        href: 'https://github.com/vuetifyjs/vuetify-loader'
      },
      {
        text: 'github',
        href: 'https://github.com/vuetifyjs/vuetify'
      },
      {
        text: 'awesome-vuetify',
        href: 'https://github.com/vuetifyjs/awesome-vuetify'
      }
    ],
    importantLinks: [
      {
        text: 'Facebook',
        href: 'https://facebook.com/thesigmacompany'
      },
      {
        text: 'Linked-In',
        href: 'https://linkedin.com/thesigmacompany'
      },
      {
        text: 'Youtube',
        href: 'https://youtube.com/thesigmacompany'
      },
      {
        text: 'Twitter',
        href: 'https://twitter.com/thesigmacompany'
      },
      {
        text: 'Articles',
        href: '#'
      }
    ],
    whatsNext: [
      {
        text: 'Explore Clients',
        href: '#'
      },
      {
        text: 'Know More About Us',
        href: '#'
      },
      {
        text: 'Frequently Asked Questions',
        href: '#'
      }
    ]
  })
}
</script>
